body {
  color: #782A7E;
  background: linear-gradient(#E6B282, #929292);
  font-family: 'Yuji Boku', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
  user-select: none;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

*:focus {
  outline: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

code {
  font-family: 'Yuji Boku', serif;
}

.gold {
  color: #bea76f;
}
.white {
  color: #782A7E;
}